
                @import "src/styles/brands/thameslink/_settings";
              
.custom-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 60px;

    @media only screen and (max-width: 870px) {
        width: 90%;
        margin: 0 auto;
    }

    &__indicator {
        position: relative;

        div {
            width: 20px;
            height: 20px;
            background: #1C184F;
            border-radius: 50%;
            position: relative;

            &::before {
                content: '';
                width: 13px;
                height: 13px;
                background:white;
                position: absolute;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            
        }

        label {
            font-size: 12px;
            position: absolute;
            display: block;
            white-space: nowrap;
            transform: translate(-50%, 10px);
            left: 50%;
        }
        

        &_current {
            div::before {
                display: none;
            }
        }
    }

    hr {
        background: #1C184F;
        height: 2px;
        flex: 1;
        opacity: 1;
        margin: 0 5px;
    }
}