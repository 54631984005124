
                @import "src/styles/brands/thameslink/_settings";
              
.railcard-options {
    scroll-margin-top: 100px; // allow for 80px height header plus 20px when scrolling down to element

    .railcards-wrapper {
        align-items: flex-start;
        display: flex;
        flex-flow: wrap;
        gap: 20px;
        justify-content: space-between;
        list-style-type: none;
        padding: 0 20px;

        @media only screen and (max-width: 820px) {
            justify-content: center;
        }

        .railcard-item-wrapper {

            .railcard-item {
                margin-bottom: 22px;
            }
        }
    }
}  
