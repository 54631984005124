
                @import "src/styles/brands/thameslink/_settings";
              
.login-modal {
  &__save {
    width: 100%;
  }

  &__actions {
    p {
      font-style: italic;
      font-size: 0.75rem;
    }

    &__button {
      display: inline;
      font-size: 0.75rem;
      padding: 0 0 3px 0;

      &--forgotten-password {
        float: right;
      }
    }

    button {
      font-style: italic;
      text-decoration: none;
    }
  }
}

.login-form {
  &__input {
    width: 100%;
  }
}

.register-form {
  legend {
    font-size: 1rem;
    font-weight: bold;
  }

  .form-check-label {
    font-size: 0.75rem;
  }

  p {
    font-size: 0.75rem;
  }

  &__success {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__success-heading {
    text-align: center;
  }
}
