
                @import "src/styles/brands/thameslink/_settings";
              
.proof-of-age {
    &__select-button {
        padding: 10px 17px;
        background: white;
        color: #444F56;
        // fill: #444F56;
        stroke: #444F56 !important;
        font-weight: bold;
        border: 1.5px solid #444F56;
        border-radius: 8px; 
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        &__name {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__number {
                font-size: 12px;
                text-align: left;
            }
        }
    }
}

.proof-of-age-form {
    &__example-document-image {
        max-width: 100%;
    }
}

.user-info {
    &__form {    
        .document__field {
            margin-top: 15px;
        }
    }
}