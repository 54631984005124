
                @import "src/styles/brands/thameslink/_settings";
              
.dob-selector {
    display: flex;

    select:nth-child(2) {
        margin: 0 10px;
    }
}

.user-info {
    &__form {
        @media only screen and (min-width: 768px) {
            padding-right: 50px;
        }
        margin-bottom: 20px;
    }
    
    &__footer {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        button {
          width: 210px;
        }
    }
}