
                @import "src/styles/brands/thameslink/_settings";
              
.railcard-filters {
    margin-bottom: 60px;
    scroll-margin-top: 100px;
    
    h1 {
        text-align: center;
    }

    &__selectors {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        select {
            max-width: 160px;
        }

        .custom-select {
            margin-bottom: 20px;

            @media only screen and (max-width: 779px) {
                flex-basis: 100%;
            }
        }
    }
}